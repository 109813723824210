
import { Component, Vue } from 'vue-property-decorator'
import certManger from '@/api/maintain/cert-manger'

@Component({
  name: 'ConsulDefaultAlert'
})
export default class ConsulDefaultAlert extends Vue {
  downFile () {
    certManger
      .reCreateConsulRootCertData()
      .then(url => {
        const saveLink = document.createElement('a')
        saveLink.href = url
        saveLink.download = 'xbgconsulroot.tar'
        saveLink.click()

        saveLink.remove()
      })
      .catch(ex => {
        this.$Message.error('下载证书失败:' + ex.message)
      })
  }
}
