import { render, staticRenderFns } from "./consul-default-alert.vue?vue&type=template&id=444c6672&scoped=true&"
import script from "./consul-default-alert.vue?vue&type=script&lang=ts&"
export * from "./consul-default-alert.vue?vue&type=script&lang=ts&"
import style0 from "./consul-default-alert.vue?vue&type=style&index=0&id=444c6672&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444c6672",
  null
  
)

export default component.exports